export const LINK_WINDOW_SIZE = 500;
export const UNKNOWN_ERROR_MESSAGE =
  "An error occurred, but your credentials are secure. Please try again, or contact support.";
export const RAMP_ORG = ["54db1a72-1b45-4e04-85c6-5fb3cfc3f7d5"];
export const NILUS_ORG_IDS = [
  "7c506156-ba23-4932-b78f-04dd828f70fb",
  "8dbeea98-b1b8-4d47-bec7-eaad5d6ce793",
  "5172c6d5-4cfa-495a-85e8-fac26ca5cc86",
  "c8eec21f-f1f7-4277-9337-106db667c3e1",
];
export const PAYONEER_ORG_IDS = [
  "2f4b6f5a-b2ed-40e1-bc4b-55e9858e5241",
  "e0db65eb-1c96-4673-bfd1-97b1e435a91b",
  "7d282d0f-8c87-43ca-9a91-b3c788621863",
];
export const REVOLUT_ORG_IDS = [
  "5861c936-9bab-486a-9bb7-f663259c6cb9",
  "f4f7c516-380b-40b9-9717-2646cdecac90",
];
export const JARIS_ORG_ID = "fa5fc6a2-15ae-44e2-a94e-468802d7f100";
export const SLOPE_ORG_IDS = ["2e05f9f7-f724-4491-822e-c246678d37bd"];
export const HIGHBEAM_ORG_IDS = ["65e25038-6be5-4288-b85a-a4bb7a862470"];

export const LINK_STATE_STORAGE_FIELD = "rutterlinkstate";
