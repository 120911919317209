import { useState } from "react";
import Spinner from "../components/Loading/Spinner";
import { UNKNOWN_ERROR_MESSAGE } from "../constants";
import { captureException } from "../sentry";
import MxLink from "./MxLink";

type Props = {
  clientSecret: string;
  bankId?: string;
  onBack: () => void;
  onCancel: () => void;
  onComplete: (publicToken: string) => void;
};

function Mx({ bankId, onComplete }: Props) {
  const [error, setError] = useState<string>("");

  function handleAuthError(e: any) {
    captureException(e);
    if (e.response.data.error_message) {
      setError(e.response.data.error_message);
    } else {
      setError(UNKNOWN_ERROR_MESSAGE);
    }
  }

  function handleComplete(rutterPublicToken: string) {
    try {
      onComplete(rutterPublicToken);
    } catch (e) {
      handleAuthError(e);
    }
  }

  return true ? (
    <>
      <div
        id="widget"
        className="flex items-center flex-column mt-4"
        style={{ justifyContent: "center", paddingBottom: "20px" }}></div>
      <MxLink bankId={bankId} onComplete={handleComplete} />
    </>
  ) : (
    <Spinner />
  );
}

export default Mx;
