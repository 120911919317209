import * as amplitude from "@amplitude/analytics-browser";
import { SearchOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Input, Spin } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { captureException } from "../sentry";
import { getBackendUrl } from "../utils";
import "./selectBank.css";

interface Props {
  setBankId: React.Dispatch<React.SetStateAction<string>>;
  setBankRoutingNumber: React.Dispatch<React.SetStateAction<string>>;
  orgId: string;
}

const SelectBank = ({ orgId, setBankId, setBankRoutingNumber }: Props) => {
  const history = useHistory();
  const [defaultBanks, setDefaultBanks] = useState([]);
  const [searchedBanks, setSearchedBanks] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);

  // Load priority (top 20) banks for initial/default display.
  React.useEffect(() => {
    // effect run once
    amplitude.setGroup("orgId", orgId);
    amplitude.track("Bank Link Initiated");

    setLoading(true);
    axios
      .get(`${getBackendUrl()}/banking/priority_banks`, {
        params: {
          org_id: orgId,
        },
      })
      .then((response) => {
        setDefaultBanks(response.data.banks);
      })
      .catch((e) => {
        captureException(e, { orgId });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (search === "") {
      setSearchedBanks([]);
    }
  }, [search]);

  const searchBanks = (e: any) => {
    const newSearch = e.target.value;
    setSearch(newSearch);
    if (newSearch === "") {
      setSearchedBanks(defaultBanks);
      return;
    }

    setLoading(true);
    axios
      .get(`${getBackendUrl()}/banking/search`, {
        params: {
          org_id: orgId,
          query: newSearch,
        },
      })
      .then((response) => {
        setSearchedBanks(response.data.banks);
      })
      .catch((e) => {
        captureException(e, { orgId });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const selectBank = (bank: any) => {
    setBankId(bank.platformId); // For TELLER or MX.
    setBankRoutingNumber(bank.routingNumber); // For PLAID.
    if (bank.platform === "TELLER") {
      history.push("/teller");
    } else if (bank.platform === "PLAID") {
      history.push("/plaid");
    } else if (bank.platform === "MX") {
      history.push("/mx");
    }
  };

  return (
    <>
      <div className="p-4">
        <div className="text-lg font-bold" style={{ textAlign: "center" }}>
          Choose your bank
        </div>
      </div>
      <Input
        autoFocus
        size="large"
        prefix={
          <SearchOutlined
            className="mr-1"
            style={{
              color: "#828282",
            }}
          />
        }
        className="m-2 font-lg"
        style={{
          width: "95%",
          borderRadius: "5px",
        }}
        placeholder="Search for a bank"
        value={search}
        onChange={searchBanks}
        allowClear></Input>
      {loading ? (
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 99,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Spin></Spin>
        </div>
      ) : searchedBanks.length > 0 ? (
        <div className="mt-2">
          {searchedBanks.map((bank: any) => {
            return (
              <HoverBackground
                key={bank.platformId}
                onClick={() => {
                  amplitude.track("Bank Selected");
                  amplitude.setGroup("bank", bank.name);
                  selectBank(bank);
                }}
                className="p-3 m-1 flex flex-end"
                style={{
                  border: "1px solid rgba(42, 47, 69, .17)",
                  borderRadius: "5px",
                }}>
                <div style={{ height: "32px" }}>
                  {/* If logoUrl is also not defined, attempting to render as an image still leads to the bank name being properly spaced out. */}
                  {bank.logoBase64 ? (
                    <img
                      style={{ height: "32px" }}
                      src={"data:image/jpeg;base64, " + bank.logoBase64}
                    />
                  ) : (
                    <img style={{ height: "32px" }} src={bank.logoUrl} alt="" />
                  )}
                </div>
                <div
                  className="ml-3"
                  style={{ maxWidth: "265px", height: "32px" }}>
                  <div className="truncate" style={{ fontSize: 13 }}>
                    {bank.name}
                  </div>
                  {bank.url ? (
                    <div
                      className="truncate"
                      style={{
                        fontSize: 10,
                        color: "#697386",
                      }}>
                      {bank.url}
                    </div>
                  ) : (
                    <div
                      style={{
                        fontSize: 10,
                        color: "#ffffff",
                      }}>
                      Bank URL
                    </div>
                  )}
                </div>
              </HoverBackground>
            );
          })}
        </div>
      ) : search ? (
        <div
          style={{
            height: 300,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}>
          No results found.
        </div>
      ) : (
        <div
          className="mt-2 ml-2 mr-3"
          style={{
            flexWrap: "wrap",
            display: "flex",
            justifyContent: "space-between",
          }}>
          {defaultBanks.map((bank: any) => {
            return (
              <HoverBackground
                key={bank.platformId}
                onClick={() => {
                  amplitude.track("Bank Selected");
                  amplitude.setGroup("bank", bank.name);
                  selectBank(bank);
                }}
                className="py-4 mt-2 flex items-center border-gray-300"
                style={{
                  flex: "1 0 50%",
                  border: "1px solid rgba(42, 47, 69, .17)",
                  borderRadius: "15px",
                  maxWidth: "160px",
                  height: "80px",
                }}>
                <div
                  style={{
                    display: "block",
                    margin: "auto",
                  }}>
                  {/* If logoUrl is also not defined, attempting to render as an image still leads to the bank name being properly spaced out. */}
                  <img
                    style={{
                      maxHeight: "50px",
                      maxWidth: "100px",
                    }}
                    src={bank.priorityLogoUrl}
                    alt=""
                  />
                </div>
              </HoverBackground>
            );
          })}
        </div>
      )}
    </>
  );
};

export default SelectBank;

const HoverBackground = styled.div`
  cursor: pointer;
  overflow: hidden;
  &:hover {
    background-color: #f9fafc;
  }
`;
