import { ConnectWidget } from "@mxenabled/web-widget-sdk";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { captureException } from "../sentry";
import Store from "../stateStore";
import { extractLinkParamsFromState, getBackendUrl } from "../utils";

interface ConnectMemberConnectedPayload {
  user_guid: string;
  session_guid: string;
  member_guid: string;
}

interface OnBackToSearchPayload {
  user_guid: string;
  session_guid: string;
  context?: string;
}

const MxLink = ({
  bankId,
  onComplete,
}: {
  bankId?: string;
  onComplete: (rutterPublicToken: string) => void;
}) => {
  const stateStore = Store.useStore();
  const [widgetUrl, setWidgetUrl] = useState("");
  let publicToken: string | undefined;

  useEffect(() => {
    let isMounted = true;

    if (!widgetUrl) {
      axios
        .post(
          `${getBackendUrl()}/mx/get_widget_url`,
          {
            ...extractLinkParamsFromState(stateStore),
            bank_id: bankId,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          },
        )
        .then((res) => {
          if (isMounted) {
            setWidgetUrl(res.data);
          }
        })
        .catch((error) => {
          captureException(error);
          window.location.replace(error.response.data);
        });
    }

    return () => {
      isMounted = false;
    };
  }, [widgetUrl]);

  const onMemberConnected = React.useCallback(
    (payload: ConnectMemberConnectedPayload) => {
      const storeMemberDetails = async () => {
        try {
          const response = await axios.post(
            `${getBackendUrl()}/mx/set_member_details`,
            {
              platform_response: payload,
              ...extractLinkParamsFromState(stateStore),
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            },
          );

          publicToken = response.data.public_token;
        } catch (error) {
          captureException(error);
        }
      };

      storeMemberDetails();
    },
    [],
  );

  const onBackToSearch = React.useCallback(
    (_payload: OnBackToSearchPayload) => {
      if (publicToken) {
        // In order to match other platforms, redirect to Rutter after confirmation at the end of the MX Link flow.

        onComplete(publicToken);
      }
    },
    [publicToken],
  );

  useEffect(() => {
    // Wait for the container element to be available in the DOM
    const container = document.getElementById("widget");

    if (widgetUrl && container) {
      const widget = new ConnectWidget({
        container: "#widget",
        url: widgetUrl ?? "",

        // MX Bank can be tested with username: mxuser, password: correct (see https://docs.mx.com/resources/test-platform/mxbank/#mx-bank-testing-scenarios)
        onMemberConnected,
        onBackToSearch,
      });
      // https://docs.mx.com/connect/guides/web-app-guide/web/getting-started
      return () => widget.unmount();
    }
  }, [widgetUrl]); // Only run this effect when widgetUrl changes

  return <div></div>;
};

export default MxLink;
