import { Alert } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import Spinner from "../components/Loading/Spinner";
import { UNKNOWN_ERROR_MESSAGE } from "../constants";
import { captureException } from "../sentry";
import Store from "../stateStore";
import { extractLinkParamsFromState, getBackendUrl } from "../utils";
import TellerLink from "./TellerLink";

type Props = {
  clientSecret: string;
  bankId?: string;
  onBack: () => void;
  onCancel: () => void;
  onComplete: (publicToken: string) => void;
};

function Teller({ bankId, onComplete }: Props) {
  const stateStore = Store.useStore();
  const [error, setError] = useState<string>("");
  const [applicationId, setApplicationId] = useState(null);
  const [environment, setEnvironment] = useState(null);
  const [institutionId, setInstitutionId] = useState(bankId);

  // We must fetch the environment before opening TellerLink, since once we open TellerConnect, we canNOT change the environment.
  useEffect(() => {
    if (!applicationId) {
      try {
        axios
          .post(
            `${getBackendUrl()}/teller/get_app_id`,
            { ...extractLinkParamsFromState(stateStore) },
            {
              headers: {
                "Content-Type": "application/json",
              },
            },
          )
          .then((res) => {
            setApplicationId(res.data.applicationId);
            setEnvironment(res.data.environment);
            setInstitutionId(res.data.institutionId ?? bankId ?? "");
          });
      } catch (error) {
        captureException(error);
      }
    }
  }, [applicationId, environment, institutionId]);

  function handleAuthError(e: any) {
    captureException(e);
    if (e.response.data.error_message) {
      setError(e.response.data.error_message);
    } else {
      setError(UNKNOWN_ERROR_MESSAGE);
    }
  }

  function handleComplete(rutterPublicToken: string) {
    try {
      onComplete(rutterPublicToken);
    } catch (e) {
      handleAuthError(e);
    }
  }

  return applicationId && environment ? (
    <>
      <div
        className="flex items-center flex-column mt-4"
        style={{ justifyContent: "center", paddingBottom: "20px" }}></div>
      <TellerLink
        applicationId={applicationId}
        environment={environment}
        bankId={institutionId}
        onComplete={handleComplete}
      />
      {error && (
        <Alert
          className="mt-4"
          type="error"
          message={"Error"}
          description={error}
          showIcon
        />
      )}
    </>
  ) : (
    <Spinner />
  );
}

export default Teller;
