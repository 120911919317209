import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  TellerConnectEnrollment,
  TellerConnectOnSuccess,
  TellerConnectOptions,
} from "teller-connect-react";
import { v4 as uuidv4 } from "uuid";
import { captureException } from "../sentry";
import Store from "../stateStore";
import { extractLinkParamsFromState, getBackendUrl } from "../utils";
import { useTellerConnectOverride } from "./useTellerConnectOverride";

// Relevant docs:
// https://github.com/tellerhq/teller-connect-react
// https://teller.io/settings/application
// https://teller.io/docs/guides/connect

const Link = ({
  applicationId,
  environment,
  bankId,
  onComplete,
}: {
  applicationId: string;
  environment: string;
  bankId?: string;
  onComplete: (rutterPublicToken: string) => void;
}) => {
  const history = useHistory();
  const stateStore = Store.useStore();
  const skipOverride = stateStore.get("skipOverride");
  const [tellerNonce, setTellerNonce] = useState("");
  useEffect(() => {
    setTellerNonce(uuidv4());
  }, []);

  const onSuccess = React.useCallback<TellerConnectOnSuccess>(
    (enrollment: TellerConnectEnrollment) => {
      const verifySignatureAndStoreAccessToken = async () => {
        try {
          const response = await axios.post(
            `${getBackendUrl()}/teller/set_access_token`,
            {
              platform_response: enrollment,
              environment,
              tellerNonce,
              ...extractLinkParamsFromState(stateStore),
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            },
          );
          onComplete(response.data.public_token);
        } catch (error) {
          captureException(error);
        }
      };
      verifySignatureAndStoreAccessToken();
    },
    [tellerNonce],
  );

  const options: TellerConnectOptions = {
    applicationId,
    environment: filterEnvironmentName(environment),
    // If provided, will open Teller Connect directly to this institution.
    institution: bankId,
    nonce: tellerNonce,
    onSuccess,
    onExit: () => {
      history.goBack();
    },
  };

  const { open, ready } = useTellerConnectOverride(options, skipOverride);

  useEffect(() => {
    if (ready) {
      open();
    }
  }, [ready, open]);

  return <div></div>;
};

// If we receive an invalid environment, always default to production.
function filterEnvironmentName(
  environment: string,
): "sandbox" | "development" | "production" {
  switch (environment) {
    case "development":
    case "production":
    case "sandbox":
      return environment;
    default:
      return "production";
  }
}

Link.displayName = "Link";

export default Link;
