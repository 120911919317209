import { Store } from "undux";
import { captureException } from "./sentry";
import { StoreType } from "./stateStore";

export function isProd() {
  return process.env.NODE_ENV === "production";
}

export function isDev() {
  return process.env.NODE_ENV === "development";
}

export function isPayoneerUrl() {
  // very simple way to determine whether this is payoneer's reverse proxy. assuming the url has payoneer in the title
  return window.location.href.includes("payoneer");
}

export function getBackendUrl() {
  const productionUrl =
    process.env.REACT_APP_RUTTER_BACKEND_URL ||
    "https://production.rutterapi.com";
  if (isPayoneerUrl()) {
    // Payoneer has a reverse proxy for our endpoints so we need to return the URL they give us
    // To ensure all backend requests to go their reverse proxy and not ours
    // This is so they can avoid being blocked in China
    return "https://rutter-production.payoneer.com";
  }
  return isProd()
    ? productionUrl
    : process.env.REACT_APP_TUNNEL_ON
    ? "https://rutter.ngrok.io"
    : "http://localhost:4000";
}

export enum STORES {
  SHOPIFY = "SHOPIFY",
  MAGENTO = "MAGENTO",
  SHOPLAZZA = "SHOPLAZZA",
  BIG_COMMERCE = "BIG_COMMERCE",
  WOO_COMMERCE = "WOO_COMMERCE",
  SQUARESPACE = "SQUARESPACE",
  WIX = "WIX",
  COMMERCELAYER = "COMMERCELAYER",
  SQUARE = "SQUARE",
  AMAZON = "AMAZON",
  EBAY = "EBAY",
  ETSY = "ETSY",
  PRESTASHOP = "PRESTASHOP",
  SHOPEE = "SHOPEE",
  SHOPER = "SHOPER",
  WEBFLOW = "WEBFLOW",
  SHOPWARE = "SHOPWARE",
  LAZADA = "LAZADA",
  WALMART = "WALMART",
  MERCADOLIBRE = "MERCADOLIBRE",
  STRIPE = "STRIPE",
  PAYPAL = "PAYPAL",
  ZID = "ZID",
  // accounting
  FRESHBOOKS = "FRESHBOOKS",
  QUICKBOOKS = "QUICKBOOKS",
  QUICKBOOKS_DESKTOP = "QUICKBOOKS_DESKTOP",
  XERO = "XERO",
  NETSUITE = "NETSUITE",
  ZOHOBOOKS = "ZOHOBOOKS",
  SAGE_BUSINESS_CLOUD = "SAGE_BUSINESS_CLOUD",
  SAGE_INTACCT = "SAGE_INTACCT",
  SAGE_50 = "SAGE_50",
  SHOPLINE = "SHOPLINE",
  WAVE = "WAVE",
  ALIBABA = "ALIBABA",
  DYNAMICS365 = "DYNAMICS365",
  TALLY = "TALLY",
  // Payment processor / Subscription billing services
  RECURLY = "RECURLY",
  CHARGEBEE = "CHARGEBEE",
  CHARGIFY = "CHARGIFY",
  GUMROAD = "GUMROAD",
  // HRIS
  QUICKBOOKS_HRIS = "QUICKBOOKS_HRIS",
  BAMBOO_HR = "BAMBOO_HR",
}

export enum ENV_TYPE {
  Sandbox = "SANDBOX",
  Prod = "PROD",
}

export function extractLinkParamsFromState(state: Store<StoreType>) {
  return {
    link_update_id: state.get("itemId") ?? undefined,
    org_id: state.get("orgId") ?? undefined,
    nonce: state.get("nonce") ?? undefined,
    origin: state.get("origin") ?? undefined,
    custom_redirect_url: state.get("customRedirectUrl") ?? undefined,
  };
}

export const getBackButtonCallback = (
  autoPlatform: StoreType["autoPlatform"],
  goBack: () => void,
  customRedirectUrl: string,
) => {
  if (autoPlatform && customRedirectUrl) {
    return window.location.replace(customRedirectUrl);
  }
  goBack();
};

export const handleClose = () => {
  try {
    window.close();
  } catch (e) {
    captureException(e);
  }
};

export const showBackButton = (
  autoPlatform: StoreType["autoPlatform"],
  customReturnUrl: string,
) => {
  return autoPlatform ? (customReturnUrl ? true : false) : true;
};
