import axios from "axios";
import React, { useEffect, useState } from "react";
import { usePlaidLink } from "react-plaid-link";
import { useHistory } from "react-router-dom";
import { captureException } from "../sentry";
import Store from "../stateStore";
import { extractLinkParamsFromState, getBackendUrl } from "../utils";

const Link = ({
  routingNumber,
  onComplete,
}: {
  routingNumber: string;
  onComplete: (rutterPublicToken: string) => void;
}) => {
  const history = useHistory();
  const stateStore = Store.useStore();
  const [plaidLinkToken, setPlaidLinkToken] = useState("");

  const onSuccess = React.useCallback(
    (public_token: string) => {
      const exchangePublicTokenForAccessToken = async () => {
        try {
          const response = await axios.post(
            `${getBackendUrl()}/plaid/set_access_token`,
            {
              public_token,
              link_token: plaidLinkToken,
              ...extractLinkParamsFromState(stateStore),
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            },
          );
          onComplete(response.data.public_token);
        } catch (error) {
          captureException(error);
        }
      };
      exchangePublicTokenForAccessToken();
    },
    [plaidLinkToken],
  );

  const config: Parameters<typeof usePlaidLink>[0] = {
    token: plaidLinkToken,
    onSuccess,
    onExit: () => {
      history.goBack();
    },
  };

  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    if (ready && plaidLinkToken !== "") {
      open();
    }
  }, [ready, open, plaidLinkToken]);

  useEffect(() => {
    if (!plaidLinkToken) {
      try {
        axios
          .post(
            `${getBackendUrl()}/plaid/get_link_token`,
            {
              routing_number: routingNumber,
              ...extractLinkParamsFromState(stateStore),
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            },
          )
          .then((res) => {
            setPlaidLinkToken(res.data.link_token);
          });
      } catch (error) {
        captureException(error);
      }
    }
  }, []);

  return <div></div>;
};

Link.displayName = "Link";

export default Link;
